<template>
  <button
    class="stb-button m-btn"
    title="Прокрутить в начало сайта"
    :style="{
      opacity: buttonVisible ? 0 : 1,
      pointerEvents: buttonVisible ? 'none' : 'auto',
    }"
    @click="scrollToTop()"
  />
</template>

<script>
const SCROLL_SHOW_POSITION = 500;

export default {
  name: 'ScrollTopButton',
  data: () => ({
    scrollPosition: 0,
  }),
  computed: {
    buttonVisible() {
      return this.scrollPosition < SCROLL_SHOW_POSITION;
    },
  },
  mounted() {
    this.checkScrollPosition();
    window.addEventListener('scroll', this.checkScrollPosition);
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.checkScrollPosition);
  },
  methods: {
    checkScrollPosition() {
      this.scrollPosition = window.scrollY;
    },
    scrollToTop() {
      this.$nextTick(() => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
      });
    },
  },
};
</script>

<style scoped lang="scss">

.stb-button {
  width: 42px;
  height: 42px;
  border-radius: 50px;
  border: 2px solid $black;
  box-shadow: 2px 2px 0 0 $black;

  background-color: $white-color;
  background-image: url("/v2/main/arrow-top.png");
  background-size: 16px 18px;
  background-position: center;
  background-repeat: no-repeat;
  position: fixed;
  z-index: 5;
  right: 60px;
  bottom: 60px;
  transition: 200ms;

  &:hover {
    box-shadow: none;
    transform: translate(2px, 2px);
  }

  @include media-down(laptop) {
    display: none;
  }
}

</style>
